/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class login.module.css
 * This JS file is intended to provide the css styles for login page design
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

 .left {
  background-color: #634699;
  background-image: url("../../../assets/images/login-purple-bg.png");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  height: 100%;
  width: 50%;
}

.formcontainer {
  position: absolute;
  left: 50%;
  top: 30%;
  right: 10%;
}

.formcontainer h1 {
  color: #ffffff;
  font-size: 35px;
  line-height: 45.5px;
  font-weight: bold;
}

.normal {
  color: #c4c4c4;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: bold;
  margin-bottom: 40px !important;
}

.regtext {
  color: #A0AEC0;
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  margin-top: 10px !important;
  padding-left: 6%;
}

.textField {
  background: transparent;
  border: #fff;
  box-sizing: border-box;
  padding-bottom: 5px !important;
  margin-bottom: 25px !important;
  border-radius: 4px;
  height: 51px;
  padding-left: 20px;
}

.mocktextField {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  padding-bottom: 5px !important;
  margin-bottom: 25px !important;
  width: 80%;
  border-radius: 5px;
  height: 51px;
  padding-left: 20px;
}

.textField label {
  padding-left: 20px !important;
  color: #A0AEC0;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14.0303px;
  line-height: 140%;
}

.rememberme label {
  color: #ffffff;
  text-align: left;
}

.btnstyle {
  text-transform: "lowercase";
  width: 100%;
  height: 40px;

}

.logocontainer {
  position: absolute;
  left: 60%;
  top: 40%;
  align-items: center;
}

.image {
  text-align: center;
  width: 500.65px;
  height: 95.21px;
  left: 50px;
  float: none;
  display: block;
}

.headp {
  color: #634699;
  font-weight: 50px;
}

@media (max-width: 700px) {
  .left {
    height: 75%;
    width: 100%;
    overflow: auto;
  }

  .formcontainer {
    text-align: center;
    left: 10%;
    top: 10%;
  }

  .logocontainer {
    position: absolute;
    right: 10%;
    top: 80%;
    left: 8%;
  }

  .image {
    float: none;
    display: block;
    width: 150.65px;
    height: 85.21px;
  }

  .textField {
    width: 90%;
  }

  .btnstyle {
    width: 90%;
  }
}