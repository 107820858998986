/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class style.css
 * This CSS file is intended to provide the css styles for match question page design
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 22 nov 2022
 */
 
.match-the-following {
  display: flex;
  justify-content: center;
  align-items: center;

}

.divider-line-with-image {
  margin-top: -60px;
  margin-left: 264px;
  word-wrap: break-word;
  width: 62px;
  padding: 20px;
  border-top: 1px solid #634699;
}

.divider-line-without-image {
  margin-top: -25px;
  margin-left: 264px;
  word-wrap: break-word;
  width: 62px;
  margin-bottom: 25px;
  border-top: 1px solid #634699;
}


.column {
  padding: 10px;
}



.left-column {
  margin-right: 10px;
}

 

.rowAoptions {
  background-color: #F8F9FA;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  height: 100px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  /* cursor: pointer; */
  word-wrap: break-word;
}

.row:hover {
  white-space: normal;
  overflow: visible;
}

.rowBoptions {
  /* background-color: #f9f9f9; */
  padding: 20px;
  margin-bottom: 10px;
  /* border: 1px solid #ddd; */
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* height: 100px; */
  background-color: #F8F9FA;
  padding: 10px;
  margin-bottom: 10px;
  /* border: 1px solid #000000; */
  border-radius: 5px;
  height: 100px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* cursor: pointer; */
  word-wrap: break-word;
}







.row.dragging {
  opacity: 0.5;
}



.row.dragging:hover {
  background-color: #634699;
}



.row.dragging-over {
  border: 2px dashed #634699;
}



.row.dragging-over:hover {
  background-color: #634699;
}



.row.dragging-over.invalid {
  border-color: #ff0000;
}