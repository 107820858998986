/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class SignUp.module.css
 * This JS file is intended to provide the css styles for signup page design
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */
 .bg-image {
  background-image:url("../../../assets/images/sign-up-purple-bg.png");
  background-size: cover;
  background-position: center;
}

.top {
  /* border-radius: 25px; */
  background: #634699;
  /* background-image: url("../../../assets/images/sign-up-purple-bg.png"); */
  background-image: url("../../../assets/images/Signupbg.png");
  
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  position: absolute;
}

.welcome {
  color: #ffffff;
  position: relative;
  display: block;
  text-align: center;
  padding: 92px;
}

.welcome h1 {
  color: #ffffff !important;
  font-size: 40px;
  line-height: 52px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
}

.normal {
  font-size: 16px;
  line-height: 22px;
  margin: 20px auto;
  max-width: 15% !important;
  position: relative;
}

.paper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  width: 452px;
  margin: 3px auto;


}

.signupicons {
  margin: 10px;
}

.signupfield {
  width: 100%;
  margin-bottom: 25px !important;
}

.signupfield fieldset {
  border-radius: 5px !important;
}

.btnstyle {
  text-transform: "uppercase";

}

.regtext {
  color: #a0aec0;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width: 700px) {
  .top {
    width: 100%;
    height: 30%;
  }

  .normal {
    max-width: 100%;
  }

  .paper {
    width: 80%;
  }

  .caption {
    font-size: 40px;
  }
}