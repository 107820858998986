.wrs_modal_title {
    background: #634699 !important;
}

.wrs_editor {
    border: 1px solid #634699 !important;
}


.wrs_panelContainer {
    background: white !important;
    border: 1px solid #634699 !important;
}

.wrs_editor .wrs_toolbar .wrs_header>button,
.wrs_editor .wrs_toolbar .wrs_header>div {
    background: #634699 !important;
}

.wrs_toolbar .wrs_header>button.wrs_selected {
    background: white !important;
}

.wrs_editor .wrs_toolbar .wrs_header>button.wrs_selected:hover,
.wrs_editor .wrs_toolbar .wrs_header>div.wrs_selected:hover {
    background: whitesmoke !important;
}

.wrs_editor .wrs_select.wrs_pressed {
    color: #fff !important;
    border: 1px solid white !important;
}

.wrs_editor .wrs_selectPanel {
    background: white !important;
    color: #634699 !important;
    border: 1px solid #634699 !important;
}

.wrs_selectPanel:hover {
    background-color: blue;
}

.wrs_editor .wrs_selectPanel div.wrs_selected {
    background: #7C55C5 !important;
    COLOR: WHITE !important;
}

.wrs_editor .wrs_select {

    color: #634699 !important;
    border: 1px solid #634699 !important;

}

.wrs_editor .wrs_select.wrs_pressed {
    color: #fff !important;
    border: 1px solid #634699 !important;
    background-color: #634699 !important;


}

.wrs_editor.wrs_withHand:not(.wrs_handDisabled):not(.wrs_toolbarHidden) .wrs_handWrapper>input {
    border: 1px solid white !important;
    background-color: #634699 !important;
}

.wrs_editor .wrs_formulaDisplay {

    border: 1px solid #634699 !important;
}

.wrs_modal_button_accept,
.wrs_modal_button_accept:active,
.wrs_modal_button_accept:focus,
.wrs_modal_button_accept:hover,
.wrs_modal_button_accept:visited {

    border: 1px solid #634699 !important;
    color: #fff !important;
    background: #634699 !important;

}

.wrs_modal_button_cancel,
.wrs_modal_button_cancel:active,
.wrs_modal_button_cancel:focus,
.wrs_modal_button_cancel:hover,
.wrs_modal_button_cancel:visited {

    border: 1px solid #634699 !important;

    color: #634699 !important;

    background-color: white !important;

}

.wrs_hand .wrs_toolbar {
    border: 1px solid #634699 !important;
    background: white !important;

}

.wrs_button_accept,
.wrs_button_accept:active,
.wrs_button_accept:focus,
.wrs_button_accept:hover,
.wrs_button_accept:visited {
    border: 1px solid #634699 !important;
    color: #fff !important;
    background: #634699 !important;
}

.wrs_button_cancel,
.wrs_button_cancel:active,
.wrs_button_cancel:focus,
.wrs_button_cancel:hover,
.wrs_button_cancel:visited {

    border: 1px solid #634699 !important;


    background-color: white !important;
    color: #634699 !important;

}

.wrs_popupmessage_panel {
    color: #634699 !important;
}

.wrs_hand .wrs_snackbar {
    background: white !important;
    color: #634699 !important;
    border: 1px solid #634699 !important;
}

.wrs_hand .wrs_snackbar .wrs_snackbarActionButton {

    color: #634699 !important;

}

.wrs_bottom_right_resizer {

    color: #634699 !important;

}