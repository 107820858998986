input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts//Helvetica.ttf') format('truetype');
}
