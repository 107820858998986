.react-calendar {
  width: 80%;
  max-width: 100%;
  height: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin: 0 auto;
}

.react-calendar * {
  font-size: 1.2em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button, .react-calendar button:disabled, .react-calendar button:hover {
  background-color: transparent;
  position: relative;
}

.react-calendar button:disabled::after {
  background-color: #f0f0f0;
}

.react-calendar button > abbr {
  /* padding: 20%;
  border-radius: 50%; */
  position: relative;
  z-index: 1;
}

.react-calendar button::after {
  content: '';
  position: absolute;
  height: 30px;
  width: 3.5em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  border-radius: 50%;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  padding-top: 23px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  min-height: 56px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 24px;
  line-height: 30.3px;
  font-style: normal;
  color: #634699;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: small;
  margin-top: 15px;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  } */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 12px 8px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover::after,
.react-calendar__tile:enabled:focus::after {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover::after,
.react-calendar__tile--now:enabled:focus::after {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active::after {
  background: #634699;
}

.react-calendar__tile--active > abbr
{
  color: white;
}

.react-calendar__tile--active:enabled:hover::after,
.react-calendar__tile--active:enabled:focus::after {
  background: #805cc2;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------- */
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #222222;
  font-size: 24px;
  font-style: normal;

  font-weight: 600;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  color: #634699;
  font-weight: 500;
  font-size: 50px;
  padding-left: 60px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: #634699;
  font-weight: 500;
  font-size: 50px;
  padding-right: 60px;
}

.react-calendar__viewContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  font-weight: 400;
  font-size: small;
  line-height: 55.54px;
}

.calender-wrapper {
  width: 100%;
  background-color: #ffffff;
}

.highlight {
  color: red !important;
}

@media (max-width: 480px) {
  .react-calendar {
    width: 60vw;
  }
}