* {
  box-sizing: border-box;
  
}

html {
  font-family: Helvetica;
  /* background: #fff; */
}

body {
  margin: 0;
  font-family: Helvetica;
  background: #F8F9FA;
}

main {
  margin-top: 6rem;
}

input::-ms-reveal,
input::-ms-clear {
        display: none;
}